import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Today is an important date.`}</p>
    <p>{`10 years abroad.
So many things have happened in these years.
Good and bad. You just need to take them and move on.
That’s what life is: you must react with the right attitude whatever happens in life.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/1*_WJBTU4NBfRfuxcMJC4Bqg.jpeg",
        "alt": "10 Years"
      }}></img></p>
    <p>{`10 years living in 3 countries.
10 years of experiences.
10 years meeting people from all around the world.
10 years where I’ve grown as family man, developer and business man (still on the making).
10 years where I had to say goodbye to people in my life.
10 years where new people entered in my life bringing happiness, joy and friendship.
10 years can be a long time.
10 years can be a short time.`}</p>
    <p>{`It all depends how you set the goals for your life.`}</p>
    <p>{`In my case, it is a very short amount of time.`}</p>
    <p>{`I took a risk leaving Italy only 10 years ago but…`}</p>
    <p>{`You have to take higher risks if you want bigger rewards!`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/1*27MoMYiQa0AIZaz6CPFGaA.jpeg",
        "alt": "Oslo"
      }}></img></p>
    <p>{`I am really looking forward to the next years in my life.
The next 10,20,30 years will be the most exciting in my life.`}</p>
    <p>{`Whatever it will offer!`}</p>
    <p>{`I am grateful for whatever happened in these 10 years.`}</p>
    <p>{`Now, I am ready for what’s next!
Bring it in Life.`}</p>
    <p>{`Let’s make these next years as the best chapter of my life.
Just wait and see.`}</p>
    <p>{`Life is amazing with the right attitude!`}</p>
    <blockquote>
      <p parentName="blockquote">{`P.S.
I will never forget where I grew up. I love my sweet country.
I love Italy and I am so proud to be Italian.
Proud to be born in Asti and enjoying most of my summers in Bari ❤`}</p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/1300/1*fbdFN2d_AinT-N9kfKPDyg.jpeg",
        "alt": "Asti"
      }}></img></p>
    <h4>{`AUTHOR`}</h4>
    <p>{`Domenico is a software developer with a passion for design, psychology, and leadership.
If you need help with software development, you need consultancy for your technical challenges or you need a leader for your software team or just want to collaborate online, feel free to contact me!`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      